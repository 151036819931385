<template>
  <div class="overflow-hidden">
    <div class="jumbotron-wrapper image">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5 pt-5 ps-0">
          <div>核心成员毕业于海内外知名院校</div>
          <div>在全球头部科技/金融/互联网公司，拥有多年工作经验</div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section
        class="container-lg px-4 d-flex flex-column justify-content-center"
      >
        <div class="text-center lh-lg">
          <div class="fs-3 d-lg-block d-none">
            <div>在非凸，我们是工程师、计算机专家、数学博士、策略研究专家、交易大咖</div>
            <div>我们使用先进的技术，研究和开发安全、透明、高质量的智能交易工具</div>
          </div>
          <div class="fs-5 d-lg-none">
            <div>在非凸，我们是工程师、计算机专家、数学博士、策略研究专家、交易大咖</div>
            <div>我们使用先进的技术，研究和开发安全、透明、高质量的智能交易工具</div>
          </div>
        </div>
        </section>
        <section>
        <section id="team" >
        <div>
          <div class="bg-light row overflow-hidden">
            <div class="col-md-6 d-flex flex-column p-0">
              <img
                src="../../assets/img/03.jpg"
                class="image03"
              />
            </div>
            <div class="col-md-4 col-10 offset-1 mt-5 pt-5" style="height:400px">
              <swiper
                class="swiper pt-4"
                :options="swiperOption"
              >
                <swiper-slide>
                  <div class="pb-3 lh-lg">
                    <p class="fs-4">
                      Rust 工程师
                    </p>
                    <p>
                      在实战经验丰富的工程师团队，和天才们死磕一个问题所带来的激情，是难以想象的，而最终攻克一个难题所带来的成就感，是我做工程师最大的快乐。工程师其实并不怕困难，为了1%的性能突破，可以抟心揖志一个月，也不要写出一些没有挑战性的代码。
                    </p>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="pb-3 lh-lg">
                    <p class="fs-4">
                      算法工程师
                    </p>
                    <p>
                      从算法框架搭建，到模型设计，再到算法实现，这是一项系统性工程。团队里有的人对金融市场很了解，有的人从互联网/科技等领域跨行过来，但每个人都有适合自己的事情做。算法每天都能得到真实市场的反馈，这也是它富有挑战的魅力所在。
                    </p>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="pb-3 lh-lg">
                    <p class="fs-4">
                      策略研究员
                    </p>
                    <p>
                      日常工作很像搞科研，从数据处理到模型调优，每一个坑都需要踩过来。每当看到模型训练快速完成并跑出惊艳的效果，都会让我价值感满满。借助AI力量，我们能更加精准地理解市场，让资产价格更加迅速、完整地反映现实世界的规律。
                    </p>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="pb-3 lh-lg">
                    <p class="fs-4">
                      职场新人
                    </p>
                    <p>
                      跟一群很酷的人，一起做很酷的事，让我成长很快。导师根据我打ACM的经历，为我定制了工作内容，设计了更匹配的岗位。身边的同事不只是奥赛金牌、开源大佬、技术极客，还是作曲家、德扑高手、桥牌冠军或者篮球队长。
                    </p>
                  </div>
                </swiper-slide>
                <swiper-slide>
                  <div class="pb-3 lh-lg">
                    <p class="fs-4">
                      实习生
                    </p>
                    <p>
                      即使我只是一个实习生，也有充分solo展现的机会。无论资历如何，只要能力强，非凸都会给你最大程度的施展空间、话语权和自主权。我们内部氛围很轻松，鼓励自由交流与合作，尊重每个人的想法，一起探索和做有意义的事。
                    </p>
                  </div>
                </swiper-slide>
                <template v-slot:pagination>
                  <div class="product-swiper-pagination text-center"></div>
                </template>
              </swiper>
            </div>
          </div>
        </div>
      </section>
      </section>
      
      <section class="d-flex flex-column mt-6">
        <div class="container-lg px-4 py-5">
          <div class="text-center">
            <h2 class="fw-bolder">核心成员</h2>
            <p class="lead fw-normal text-muted mb-5">科技赋能投资</p>
          </div>
          <div class="row mt-5">
            <div
              class="
                flex-row card text-muted d-flex col-md-4 p-3 justify-content-center
              "
            >
              <div class="p-2">
                <p class="text-dark fw-bolder fs-4">李佐凡</p>
                <div class="hr"></div>
                <p class="mb-3">联合创始人 & CTO</p>
                <p class="fst-italic">宾夕法尼亚大学</p>
                <p class="fst-italic">上海交通大学 ACM 班</p>
                <p class="fst-italic">曾就职 BlackRock</p>
                <p class="fst-italic">12 年金融行业从业经验</p>
              </div>
            </div>
            <div class="flex-row card text-muted d-flex col-md-4 p-3 justify-content-center">
              <div class="p-2">
                <p class="text-dark fw-bolder fs-4">王浚澎</p>
                <div class="hr"></div>
                <p class="mb-3">联合创始人 & CEO</p>
                <p class="fst-italic">大连理工大学</p>
                <p class="fst-italic">新加坡知名基金联合创始人</p>
              </div>
            </div>
            <div class="flex-row card text-muted d-flex col-md-4 p-3 justify-content-center">
              <div class="p-2">
                <p class="text-dark fw-bolder fs-4">郑媛姿</p>
                <div class="hr"></div>
                <p class="mb-3">首席运营官</p>
                <p class="fst-italic">考文垂大学</p>
                <p class="fst-italic">专注企业长远战略规划</p>
                <p class="fst-italic">负责公司整体运营</p>
              </div>
            </div>
            <div class="flex-row card text-muted d-flex col-md-4 p-3 justify-content-center">
              <div class="p-2">
                <p class="text-dark fw-bolder fs-4">朱为</p>
                <div class="hr"></div>
                <p class="mb-3">北京分公司合伙人</p>
                <p class="fst-italic">华中科技大学</p>
                <p class="fst-italic">10 年互联网资深架构师</p>
              </div>
            </div>
            <div class="flex-row card text-muted d-flex col-md-4 p-3 justify-content-center">
              <div class="p-2">
                <p class="text-dark fw-bolder fs-4">乔丹</p>
                <div class="hr"></div>
                <p class="mb-3">非凸AI实验室主任</p>
                <p class="fst-italic">北京大学</p>
               <p class="fst-italic">大型互联网公司10年从业经验</p>
                <p class="fst-italic">负责搜索、推荐等大规模分布式机器学习策略的开发和迭代</p>
              </div>
            </div>
            <div class="flex-row card text-muted d-flex col-md-4 p-3 justify-content-center">
              <div class="p-2">
                <p class="text-dark fw-bolder fs-4">曹迅</p>
                <div class="hr"></div>
                <p class="mb-3">新加坡分公司合伙人</p>
                <p class="fst-italic">大连理工大学</p>
                <p class="fst-italic">新加坡知名团队核心架构师</p>
              </div>
            </div>
            <!-- <div class="flex-row card text-muted d-flex col-md-3 p-3 justify-content-center">
              <div class="p-2">
                <p class="text-dark fw-bolder fs-4">Kane</p>
                <div class="hr"></div>
                <p class="mb-3">深圳分公司合伙人</p>
                <p class="fst-italic">武汉大学</p>
                <p class="fst-italic">多年工程实践及策略研究经验</p>
              </div>
            </div>
             <div class="flex-row card text-muted d-flex col-md-3 p-3 justify-content-center">
              <div class="p-2">
                <p class="text-dark fw-bolder fs-4">Harold</p>
                <div class="hr"></div>
                <p class="mb-3">产品总监</p>
                <p class="fst-italic">中国矿业大学</p>
                <p class="fst-italic">7 年金融从业经验</p>
                <p class="fst-italic">专注于打造优质算法产品</p>
              </div>
            </div> -->
          </div>
        </div>
      </section>
      
      <section class="bg-light py-5">
        <div class="container-lg px-4 gx-5 my-5">
          <div class="text-center">
            <h2 class="fw-bolder">团队背景</h2>
            <p class="lead fw-normal text-muted mb-5">
              团队成员来自全球知名高校和企业
            </p>
          </div>
          <div
            class="
              row row-cols-2 row-cols-md-4 row-cols-xl-6
              justify-content-center
            "
          >
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/北京大学.jpg"
                  alt="北京大学1"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/清华大学.jpg"
                  alt="清华大学"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/上海交通大学.jpg"
                  alt="上海交通大学7"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/复旦大学.jpg"
                  alt="复旦大学6"
                />
              </div>
              </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/同济大学.jpg"
                  alt="同济大学21"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/浙江大学.jpg"
                  alt="浙江大学5"
                />
              </div>
              </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/中国科学技术大学.jpg"
                  alt="中国科学技术大学"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/武汉大学.jpg"
                  alt="武汉大学9"
                />
              </div>
              </div>
              <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/厦门大学.jpg"
                  alt="厦门大学"
                />
              </div>
              </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/南开大学.jpg"
                  alt="南开大学21"
                />
              </div>
            </div>
             <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/上海财经大学.jpg"
                  alt="上海财经大学"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/东北大学.jpg"
                  alt="东北大学"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/重庆大学.jpg"
                  alt="重庆大学"
                />
              </div>
               </div>
                <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/电子科技大学.jpg"
                  alt="电子科技大学"
                />
              </div>
               </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/华中科技大学.jpg"
                  alt="华中科技大学5"
                />
              </div>
            </div>
             <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/大连理工大学.jpg"
                  alt="大连理工大学"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/四川大学.png"
                  alt="四川大学"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/牛津大学.png"
                  alt="牛津大学"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/Berkeley.png"
                  alt="Berkeley"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/CMU.png"
                  alt="CMU"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/Cornell.png"
                  alt="Cornell"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/ETH.jpg"
                  alt="苏黎世联邦理工学院8"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/新加坡国立.jpg"
                  alt="新加坡国立大学"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/南洋理工.jpg"
                  alt="南洋理工12"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/宾夕法尼亚大学.jpg"
                  alt="宾夕法尼亚大学"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/鲁汶大学.jpg"
                  alt="鲁汶大学45"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/leeds.jpg"
                  alt="利兹大学"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/普渡大学.jpg"
                  alt="普渡大学"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/早稻田大学.jpg"
                  alt="日本早稻田大学300"
                />
              </div>            
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/BlackRock.jpg"
                  alt="BlackRock"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/acm.png"
                  alt="acm"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/字节跳动.jpg"
                  alt="字节跳动"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/百度.jpg"
                  alt="百度"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/腾讯.jpg"
                  alt="腾讯"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/京东.jpg"
                  alt="京东"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/college/快手.jpg"
                  alt="快手"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="py-5">
        <div class="container-lg px-4 my-5">
          <div class="text-center">
            <h2 class="fw-bolder">合作伙伴</h2>
            <p class="lead fw-normal text-muted mb-5">技术合作与交流</p>
          </div>
          <div
            class="
              row row-cols-2 row-cols-sm-4 row-cols-xl-6
              justify-content-center
            "
          >
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/国泰君安证券.png"
                  alt="国泰君安证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/海通证券.png"
                  alt="海通证券"
                />
              </div>
            </div>
             <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/银河证券.png"
                  alt="银河证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/中泰证券.png"
                  alt="中泰证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/中信证券.png"
                  alt="中信证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/国投证券.jpg"
                  alt="国投证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/东北证券.png"
                  alt="东北证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/国联证券.jpg"
                  alt="国联证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/华鑫证券.png"
                  alt="华鑫证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/中信建投证券.jpg"
                  alt="中信建投证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/财通证券.png"
                  alt="财通证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/申万宏源证券.png"
                  alt="申万宏源证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/华泰证券.png"
                  alt="华泰证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/广发证券.png"
                  alt="广发证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/太平洋证券.png"
                  alt="太平洋证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/招商证券.png"
                  alt="招商证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/国信证券.png"
                  alt="国信证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/国金证券.png"
                  alt="国金证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/长城证券.jpg"
                  alt="长城证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/长江证券.png"
                  alt="长江证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/上海证券.jpg"
                  alt="上海证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/天风证券.png"
                  alt="天风证券"
                />
              </div>
            </div>
             <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/光大证券.jpg"
                  alt="光大证券"
                />
              </div>
            </div>
             <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/恒泰证券.jpg"
                  alt="恒泰证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/兴业证券.png"
                  alt="兴业证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/东吴证券.png"
                  alt="东吴证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/华西证券.jpg"
                  alt="华西证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/方正证券.jpg"
                  alt="方正证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/德邦证券.jpg"
                  alt="德邦证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/西部证券.jpg"
                  alt="西部证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/第一创业.jpg"
                  alt="第一创业"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/华福证券.png"
                  alt="华福证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/江海证券.png"
                  alt="江海证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/山西证券.jpg"
                  alt="山西证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/平安证券.png"
                  alt="平安证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/粤开证券.jpg"
                  alt="粤开证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/东方证券.jpg"
                  alt="东方证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/申港证券.jpg"
                  alt="申港证券"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/迅投.png"
                  alt="迅投"
                />
              </div>
            </div>
            <div class="col">
              <div class="text-center">
                <img
                  class="img-fluid mb-4"
                  src="../../assets/img/partner/朴赛.jpg"
                  alt="朴赛"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
       <section class="bg-light">
        <div class="container-lg px-4 py-5">
          <div class="row fs-1 fw-bold d-inline-block mb-3 ms-2">
            Q&A
            <div class="hr gradient-default"></div>
          </div>
          <div class="border-bottom py-3">
            <div class="fs-2 position-relative">
              非金融背景的工程师进入数智交易行业，可以吗？
              <a
                class="stretched-link"
                role="button"
                href="#"
                data-bs-toggle="collapse"
                data-bs-target="#qa3"
                aria-expanded="false"
              ></a>
            </div>
            <div id="qa3" class="collapse">
              <div class="border-0 py-4 fs-5">
                <ul>
                  <li>可以。技术应用于产品业务，而不同业务场景之间的技术又是高度相似的。比如，智能交易系统是一个很复杂的技术架构，需要思考：如何实现高可靠和高并发，如何平衡吞吐和延迟，如何根据业务发展路线来制定技术路线等。这些思考问题的方法会在不同的技术架构中重复出现。所以跨行业非但不会成为障碍，还会在交流中带来新的灵感。</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="border-bottom py-3">
            <div class="fs-2 position-relative">
              物理专业跨行做策略研究，可以吗？
              <a
                class="stretched-link"
                role="button"
                data-bs-toggle="collapse"
                data-bs-target="#qa1"
                aria-expanded="false"
              ></a>
            </div>
            <div id="qa1" class="collapse">
              <div class="border-0 py-4 fs-5">
                <ul>
                  <li class="mb-2">
                    可以。物理研究和策略研究好像是两条不相交的平行线，但实际上二者有很多相似的地方。比如，二者都需要处理海量的数据，进行统计、分析和可视化，只是数据的来源和对象不同。对于策略研究来说，相较金融知识，与数据和模型打交道的能力更加重要。所以，不只是物理出身的人，还有其他理工科研究背景的人都适合做策略研究。
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="bg-light pt-5 life anything">
        <div class="text-center lh-lg text-light my-5">
          <div class="fs-1 fw-bold">加入非凸团队</div>
          <div class>与优秀的人一起团结前进，你会收获更伟大的成就！</div>
          <router-link
            role="button"
            to="/join"
            class="
              btn btn-outline-light mt-3 rounded-pill
              text-decoration-none
            "
            >今天申请</router-link
          >
        </div>
        </section>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "team",
  components: {
    Swiper,
    SwiperSlide,
  },data() {
    return {
      notTouched: false,
      collapse: 0,
      swiperOption: {
        loop: true,
        direction: 'vertical',
        allowTouchMove: false,
        pagination: {
          el: ".product-swiper-pagination",
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: true,
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.anything {
  background-size: cover;
      background-position: center;background-image:url('~@/assets/img/01.jpg')
}
.jumbotron-wrapper.image {
  background-image: url("~@/assets/img/about/team_banner.jpg");
  background-position-y: 40%;
}
.cover-text > div {
  font-size: 1.8rem;
  font-weight: 400;
  filter: drop-shadow(2px 4px 6px black);
}
.card {
  border: none;
  border-radius: 0;
}
.card p {
  font-size: 1rem;
  font-weight: normal;
  color: $--ft-gray;
  margin-bottom: 0;
}
.card > div {
  width: 20rem;
}
.image03 {
  height: 450px;
}
@media screen and (max-width: map-get($container-max-widths, lg)) {
  .cover-text {
    margin-top: 3rem !important;
  }
  .cover-text > div {
    font-size: 1.25rem;
    text-align: center;
  }
  .image03 {
    height: 300px !important;
  }
}
</style>
